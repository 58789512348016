export const theme = {
  brand: {
    blue: '#00A3C7',
    blue10: 'rgba(0, 163, 199, 0.10)',
    blue20: 'rgba(0, 163, 199, 0.20)',
    red: '#D80054',
    redHover: '#9E003D',
  },
  text: {
    main: '#1D253C',
    second: '#000000',
    white: '#ffffff',
    darkMain: '#002B40',
  },
  cards: {
    red: '#C70F55',
    green: '#66C1BF',
    yellow: '#F7A823',
  },
  grey: {
    keyline: '#ececec',
    heylineDots: '#e5e5e5',
    keylineDark: '#D9D9D9',
    inputHover: '#3D3D3B',
    scroll: '#DEDEDE',
    courseTemplate: '#1D1E20',
    product: '#DADADA',
  },
  support: {
    red: '#d0021b',
    green: '#4caf50',
  },
  background: {
    grey: '#F5F7FA',
    hpHeader: '#fafafa',
    courses: '#F6F6F6',
    product: '#F5F5F5',
   
  },
  cursorFollowSize: 40,
}

export default theme
